import {
  IonAvatar, IonContent, IonItem, IonLabel, IonList,
} from '@ionic/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../../hooks/useFormatDate';
import { CommentsAndRates } from '../../../../interfaces';
import { AppContextState } from '../../../../state/AppContext';

const Comments: React.FC = () => {
  const { t } = useTranslation();
  const { formatIsoDate } = useFormatDate();

  const contextState = useContext(AppContextState);
  const { commentsAndRates } = contextState;

  if (!commentsAndRates) {
    return (
      <>
        <p className="h4">{t('edNoCommentsTitle')}</p>
        <p className="user_comment">{t('edNoComments')}</p>
      </>
    );
  }

  return (
    <IonContent id="comments-container">
      <IonList>
        {commentsAndRates.map((comment: CommentsAndRates) => (
          <IonItem key={comment.id}>
            <IonAvatar className="comment-avatar">
              <img src="assets/images/logo.png" alt="user emmit comment to expert" />
            </IonAvatar>
            <IonLabel className="comment-content">
              <p className="comment-reviewer">
                {comment.reviewer_name}
                <span className="comment-date">{formatIsoDate(comment.updated_at, 'DD')}</span>
              </p>
              <p className="user-comment">{comment.review}</p>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </IonContent>
  );
};

export default Comments;
