/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useContext, useState, useEffect } from 'react';
import {
  IonAvatar, IonButton, IonCard, IonCardContent,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { userProfileImage } from '../../../utils';
import { AppointmentDetail } from '../../../interfaces/clientInterfaces';
import { AppContextState } from '../../../state/AppContext';
import useLoader from '../../../hooks/useLoader';
import { rateUser } from '../../../services/client';

interface ComponentProps {
  service: AppointmentDetail;
  close: () => void;
  valid: () => void;
}

const RateService: React.FC<ComponentProps> = ({ service, close, valid }) => {
  const { t } = useTranslation();
  const { setLoader } = useLoader();
  const jwt = sessionStorage.getItem('jwt') || '';

  const [starRate, setStarRate] = useState<number>(0);
  const [userComment, setUserComment] = useState<string>('');
  const [canSendComment, setCanSendComment] = useState<boolean>(false);
  const contextState = useContext(AppContextState);

  const { profile } = contextState;
  const { user_profile } = profile;
  const ratingUser = user_profile.type === 'Client' ? service.client : service.expert;
  const userToRate = user_profile.type === 'Client' ? service.expert : service.client;

  useEffect(() => {
    if (userComment.length > 0 && starRate > 0) setCanSendComment(true);
    else setCanSendComment(false);
  }, [starRate, userComment]);

  const submitComments = () => {
    const reviewerID = user_profile.type === 'Client' ? service.user_id : service.expert_id;
    const reviewerName = user_profile.type === 'Client' ? service.client.first_name : service.expert.first_name;
    const ratedUserID = user_profile.type === 'Client' ? service.expert_id : service.user_id;
    const ratedUserName = user_profile.type === 'Client' ? service.expert.first_name : service.client.first_name;
    setLoader(true);
    const review = {
      user_id: ratedUserID,
      reviewer_id: reviewerID,
      user_name: ratedUserName,
      reviewer_name: reviewerName,
      appointment_id: service.id,
      rate: starRate,
      review: userComment,
    };
    rateUser(jwt, review)
      .then(() => {
        setLoader(false);
        valid();
        close();
      })
      .catch((() => {
        setLoader(false);
        close();
      }));
  };

  return (
    <div id="rate-video-chat">
      <div className="header">
        <div className="userImage client">
          <IonAvatar className="rounded-circle userProfileImage">
            <img
              src={userProfileImage('Client' || '', service.client.uuid || '')}
              alt="user"
            />
          </IonAvatar>
        </div>
        <div className="userImage expert">
          <IonAvatar className="rounded-circle userProfileImage">
            <img
              src={userProfileImage('Expert' || '', service.expert.uuid || '')}
              alt="user"
            />
          </IonAvatar>
        </div>
        <p className="message">{t('DC_L13')}</p>
      </div>
      <div className="title text-center ion-padding-horizontal">
        <p>{t('DC_L14', { name: ratingUser.first_name })}</p>
        <p>{t('DC_L15', { name: userToRate.first_name })}</p>
      </div>
      {user_profile.type === 'Expert' && (
        <IonCard color="secondary" href="mailto:donations@mindswithpurpose.com">
          <IonCardContent>
            {t('DC_L24')}
            &nbsp; donations@mindswithpurpose.com
          </IonCardContent>
        </IonCard>
      )}
      <div className="rating">
        <input
          className="rateInput"
          type="radio"
          id="star5"
          name="rating"
          onClick={() => setStarRate(5)}
          value="5"
        />
        <label htmlFor="star5" />
        <input
          className="rateInput"
          type="radio"
          id="star4"
          name="rating"
          onClick={() => setStarRate(4)}
          value="4"
        />
        <label htmlFor="star4" />
        <input
          className="rateInput"
          type="radio"
          id="star3"
          name="rating"
          onClick={() => setStarRate(3)}
          value="3"
        />
        <label htmlFor="star3" />
        <input
          className="rateInput"
          type="radio"
          id="star2"
          name="rating"
          onClick={() => setStarRate(2)}
          value="2"
        />
        <label htmlFor="star2" />
        <input
          className="rateInput"
          type="radio"
          id="star1"
          name="rating"
          onClick={() => setStarRate(1)}
          value="1"
        />
        <label htmlFor="star1" />
      </div>
      <div className="comments text-center">
        <p className="h4 text-center mt-4">{t('DC_L16')}</p>
        <textarea
          name="videochat_comment"
          id="videochat_comment"
          onChange={(e) => setUserComment(e.target.value)}
        />
      </div>
      <IonButton
        className="ion-margin-top"
        shape="round"
        onClick={() => submitComments()}
        disabled={!canSendComment}
      >
        {t('DC_L17')}
      </IonButton>
    </div>
  );
};

export default RateService;
