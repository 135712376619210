/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { LOADER } from '../../../constants/constants';
import { General, Logintype } from '../../../interfaces';
import Facebook from '../../../services/facebookAuth';
import LinkedInPage from '../../../services/linkedinAuth';
import { AppContextDispatch } from '../../../state/AppContext';
import LoginForm from './LoginForm';

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  onSuccess: (user: Logintype) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onFailure: (data: object) => void;
  // eslint-disable-next-line no-unused-vars
  setKeepLoged: (data: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  loginUserType: (data: 'SearchExpert' | 'Expert') => void;
  showPrivacy: () => void;
  logginAs: 'SearchExpert' | 'Expert';
  background: string;
  params: Record<any, unknown>;
  keepLoged: boolean;
}

const Description: React.FC<ComponentProps> = ({
  showPrivacy,
  onSuccess,
  onFailure,
  setKeepLoged,
  loginUserType,
  logginAs,
  background,
  params,
  keepLoged,
}) => {
  const { t } = useTranslation();
  const dispatch = useContext(AppContextDispatch);
  const [modalSelector, setModalSelector] = useState(false);
  const [activeSegment, setActiveSegment] = useState<'SearchExpert' | 'Expert'>(
    'SearchExpert',
  );

  const dipatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    dispatch({
      type: LOADER,
      payload,
    });
  };

  useEffect(() => {
    loginUserType(activeSegment);
  }, [activeSegment]);

  return (
    <>
      <IonCol
        size="12"
        sizeMd="12"
        sizeLg="6"
        className="descriptionLogin ion-padding ion-align-items-center"
        style={{ minHeight: '100vh' }}
      >
        <div
          className={`collapsableContainer ${!modalSelector && 'collapsed'}`}
        >
          <LoginForm
            keepLoged={keepLoged}
            setKeepLoged={setKeepLoged}
            loginUserCallback={onSuccess}
          />
          <IonText className="form-divider">{t('formDivider')}</IonText>
          <br />
          <IonButton
            shape="round"
            fill="clear"
            color="secondary"
            type="submit"
            expand="block"
            onClick={() => setModalSelector(false)}
          >
            {t('continueSocial')}
          </IonButton>
        </div>
        <div className={`collapsableContainer ${modalSelector && 'collapsed'}`}>
          <h3 style={{ marginTop: '2rem' }}>{t('userLoginBothTypesTitle')}</h3>
          <div
            className="ion-padding"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <IonSegment
              scrollable
              value={activeSegment}
              onIonChange={(e) => setActiveSegment(e.detail.value as 'SearchExpert' | 'Expert')}
              mode="ios"
              color="secondary"
            >
              <IonSegmentButton data-testid="SearchExpert" value="SearchExpert">
                <IonLabel>{t('SearchExpert')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton data-testid="Expert" value="Expert">
                <IonLabel>{t('Expert')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <p>
                    {t(
                      background === 'SearchExpert'
                        && logginAs === 'SearchExpert'
                        ? 'WelcomDescriptionClient'
                        : 'WelcomDescriptionExpert',
                    )}
                  </p>
                  <IonList className="ion-no-padding" lines="none">
                    <IonItem>
                      <IonCheckbox
                        className="keeploged-checkbox"
                        mode="ios"
                        color="primary"
                        checked={keepLoged}
                        onIonChange={(e) => setKeepLoged(e.detail.checked)}
                        slot="start"
                      />
                      <IonLabel className="keeploged-checkbox-message">
                        {t('keepLog')}
                      </IonLabel>
                    </IonItem>
                  </IonList>
                  {params.code || params.error ? (
                    <LinkedInPopUp />
                  ) : (
                    <LinkedInPage
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      setLoader={() => dipatchLoader(true)}
                      t={t}
                    />
                  )}
                  {/* {background === 'SearchExpert' && logginAs === 'SearchExpert' && (
                    <Facebook
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      setLoader={() => dipatchLoader(true)}
                      t={t}
                    />
                  )} */}
                  {/* <IonText className="form-divider">{t('formDivider')}</IonText>
                  <br />
                  <IonButton
                    shape="round"
                    fill="clear"
                    color="secondary"
                    type="submit"
                    expand="block"
                    onClick={() => setModalSelector(true)}
                  >
                    {t('continueMail')}

                  </IonButton> */}
                  <p
                    style={{
                      textAlign: 'justify',
                      marginTop: '2rem',
                      fontSize: '0.7rem',
                    }}
                  >
                    {t(
                      background === 'Expert'
                        ? 'ModalExpertDescription'
                        : 'ModalSearchDescription',
                    )}
                  </p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
        <span className="PrivacyAnchor" onClick={showPrivacy}>
          {t('Privacy')}
        </span>
      </IonCol>
    </>
  );
};

export default Description;
