import {
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonText,
  IonChip,
  IonButton,
  IonIcon,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { rocketOutline, arrowBack } from 'ionicons/icons';
import useBaseInformation from '../../hooks/useBaseInformation';
import useLoader from '../../hooks/useLoader';
import {
  FunctionsBase,
  IndustriesBase,
  Skill as SkillObject,
} from '../../interfaces';
import { ExpertDetail, Skill } from '../../interfaces/clientInterfaces';
import { getExpertsByID } from '../../services/client';
import { AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import Highlights from '../../components/userProfile/Highlights';
import Languages from '../../components/userProfile/Languages';
import WorkExperience from '../../components/userProfile/WorkExperience';

const RequestExpert: React.FC = () => {
  const jwt = sessionStorage.getItem('jwt') || '';
  const { t } = useTranslation();

  const { setLoader } = useLoader();
  const history = useHistory();
  const [validateBaseInformation] = useBaseInformation();

  const contextState = useContext(AppContextState);

  const { functions, industries } = contextState;

  const [expertIndustries, setExpertIndustries] = useState<IndustriesBase[]>(
    [],
  );
  const [expertFunctions, setExpertFunctions] = useState<FunctionsBase[]>([]);
  const [expertSkills, setExpertSkills] = useState<SkillObject[]>([]);
  const [expert, setExpert] = useState<ExpertDetail>();

  const { id } = useParams<{ id: string }>();

  const showExpertProfile = () => {
    setLoader(true);
    getExpertsByID(jwt, parseInt(id, 10)).then(({ data }) => {
      setExpert(data);
      setLoader(false);
    });
  };

  useEffect(() => {
    if (id) showExpertProfile();
    return () => {
      setExpert(undefined);
    };
  }, [id]);

  useEffect(() => {
    if (expert && industries && functions) renderFunctionsBadges();
  }, [expert, industries, functions]);

  useEffect(() => {
    validateBaseInformation();
  }, []);

  const renderFunctionsBadges = () => expert?.skills.forEach((skill: Skill) => {
    const industry = industries?.filter(
      (indus) => indus.id === parseInt(skill.industry, 10),
    );
    if (
      industry
        && !expertIndustries.some((item) => item.id === industry[0].id)
    ) {
      const newExpertIndustries = expertIndustries;
      newExpertIndustries.push(industry[0]);
      setExpertIndustries(newExpertIndustries);
    }
    skill.function.forEach((func) => {
      const job = functions?.filter(
        (item) => item.id === parseInt(func.job_functions, 10),
      );
      if (job && !expertFunctions.some((item) => item.id === job[0].id)) {
        const newExpertFunctions = expertFunctions;
        newExpertFunctions.push(job[0]);
        setExpertFunctions(newExpertFunctions);
        func.skills.forEach((currentSkill) => {
          const matchSkill = job[0].skills.filter(
            (item) => item.id === parseInt(currentSkill, 10),
          );
          if (
            matchSkill.length > 0
              && !expertSkills.some((item) => item.id === matchSkill[0].id)
          ) {
            const newSkillArray = expertSkills;
            newSkillArray.push(matchSkill[0]);
            setExpertSkills(newSkillArray);
          }
        });
      }
    });
  });

  return (
    <IonGrid className="controlled-width">
      <IonButton
        className="ion-margin-bottom"
        color="secondary"
        shape="round"
        fill="outline"
        onClick={() => history.goBack()}
      >
        <IonIcon icon={arrowBack} className="ion-margin-end" />
        {t('return')}
      </IonButton>
      <IonRow className="ion-justify-content-center">
        <IonCol size="6" className="text-center">
          <IonAvatar className="userProfileImage">
            <img
              src={userProfileImage('Expert', expert?.uuid || '')}
              alt="user"
            />
          </IonAvatar>
          <IonText color="primary">
            <h1>{expert?.first_name}</h1>
          </IonText>
          <IonButton
            className="ion-margin-bottom"
            onClick={() => history.push(`/user/request_service/${expert?.id}`)}
          >
            <IonIcon icon={rocketOutline} className="ion-margin-end" />
            {t('serviceModal')}
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-around">
        {expertIndustries.map((indus) => (
          <IonChip
            key={indus.id + indus.name}
            className="expert-badges"
            color="primary"
          >
            {indus.name}
          </IonChip>
        ))}
        {expertFunctions.map((func) => (
          <IonChip
            key={func.id + func.name}
            className="expert-badges"
            color="secondary"
          >
            {func.name}
          </IonChip>
        ))}
        {expertSkills.map((skill) => <IonChip key={skill.id + skill.name} className="expert-badges" color="tertiary">{skill.name}</IonChip>)}
      </IonRow>
      <IonRow>
        <IonText
          className="ion-text-justify"
          style={{ maxWidth: '650px', margin: '2rem auto 1.5rem' }}
        >
          {expert?.about.aboutBody}
        </IonText>
      </IonRow>
      <Languages languagesArray={expert?.languages_skill} />
      <WorkExperience works={expert?.work_histories} />
      <Highlights highlightsArray={expert?.career_highlights} />
      <IonRow className="ion-justify-content-center">
        <IonButton
          className="ion-margin-bottom"
          size="large"
          onClick={() => history.push(`/user/request_service/${expert?.id}`)}
        >
          <IonIcon icon={rocketOutline} className="ion-margin-end" />
          {t('serviceModal')}
        </IonButton>
      </IonRow>
    </IonGrid>
  );
};

export default RequestExpert;
