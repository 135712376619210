/* eslint-disable no-nested-ternary */
import {
  IonButton,
  IonGrid,
  IonLabel,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonAvatar,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../hooks/useFormatDate';
import { ClientPaymentDetail } from '../../interfaces';
import { AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import ModalWrapper from '../modalWrapper/ModalWrapper';
import PaymentModal from './PaymentModal';
import './table.scss';

type Service = ClientPaymentDetail[] | [];
type SelectableSegments = string | undefined;

const PaymentsTable: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);

  const { formatIsoDate } = useFormatDate();
  const [mentoring, setMentoring] = useState<Service>([]);
  const [coaching, setCoaching] = useState<Service>([]);
  const [express, setExpress] = useState<Service>([]);
  const [modalPayment, setModalPayment] = useState<ClientPaymentDetail | undefined>(undefined);

  const [activeSegment, setActiveSegment] = useState<SelectableSegments>(
    'express',
  );

  const { clientPayments, services, profile } = contextState;
  // eslint-disable-next-line camelcase
  const { user_profile } = profile;

  useEffect(() => {
    if (services && clientPayments) sortPayments();
  }, [services, clientPayments]);

  const sortPayments = () => {
    if (clientPayments && services) {
      clientPayments.forEach((element) => {
        const service = services.find(
          (o) => o.id === element.appointments[0]?.service_id,
        );
        if (service) {
          if (service.type.includes('xpres')) {
            const isServiceInArray = express.find((o) => o.id === element.id);
            if (!isServiceInArray) setExpress((rest) => [...rest, element]);
          } else if (service.type.includes('entorin')) {
            const isServiceInArray = mentoring.find((o) => o.id === element.id);
            if (!isServiceInArray) setMentoring((rest) => [...rest, element]);
          } else {
            const isServiceInArray = coaching.find((o) => o.id === element.id);
            if (!isServiceInArray) setCoaching((rest) => [...rest, element]);
          }
        }
      });
    }
  };

  const renderTable = (serviceArray: Service) => serviceArray.map((serv: ClientPaymentDetail) => {
    const userName = user_profile.type === 'Client'
      ? serv.appointments[0]?.expert_full_name
      : serv.appointments[0]?.user_full_name;
    const userType = user_profile.type === 'Client' ? 'expert' : 'client';
    const userUid = user_profile.type === 'Client'
      ? serv.appointments[0]?.expert_uid
      : serv.appointments[0]?.client_uid;
    const rawAmmount = serv.invoice?.amount || 0;
    return (
      <tr key={serv.id} className="ion-padding">
        <td className="userCell" data-label={t('CP_L2')}>
          <IonAvatar style={{ width: '80px', height: '80px', margin: '0.8rem auto' }}>
            <img
              src={userProfileImage(userType, userUid)}
              alt="user"
            />
          </IonAvatar>
          <p className="m-0">{userName}</p>
        </td>
        <td data-label={t('CP_L3')}>
          {formatIsoDate(serv.appointments[0]?.start_date)}
        </td>
        <td data-label={t('CP_L4')}>
          $
          {rawAmmount.toFixed(2)}
          {' '}
          {serv.currency || 'USD'}
        </td>
        <td data-label={t('CP_L5')}>
          <p className="m-0">{serv.paid ? t('CP_L7') : t('CP_L8')}</p>
        </td>
        <td>
          <IonButton
            fill="solid"
            color="secondary"
            shape="round"
            onClick={() => setModalPayment(serv)}
          >
            {t('CP_L6')}
          </IonButton>
        </td>
      </tr>
    );
  });

  if (!clientPayments) return null;

  return (
    <>
      <div className="section-title">
        <h2 className="pr-3">{t('SC_L1')}</h2>
        <span className="title-border" />
      </div>
      <div id="paymentTable" className="ion-padding">
        <IonSegment
          scrollable
          value={activeSegment}
          onIonChange={(e) => setActiveSegment(e.detail.value)}
        >
          <IonSegmentButton data-testid="express" value="express">
            <IonLabel>{t('express_service')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton data-testid="mentoring" value="mentoring">
            <IonLabel>{t('mentoring_service')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton data-testid="coaching" value="coaching">
            <IonLabel>{t('coaching_service')}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonGrid id="appSlider" className="ion-margin-top ion-no-padding">
          <IonRow>
            <IonCol>
              <table>
                <thead>
                  <tr>
                    <th>{t('CP_L2')}</th>
                    <th>{t('CP_L3')}</th>
                    <th>{t('CP_L4')}</th>
                    <th>{t('CP_L5')}</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {activeSegment === 'express' && renderTable(express)}
                  {activeSegment === 'mentoring' && renderTable(mentoring)}
                  {activeSegment === 'coaching' && renderTable(coaching)}
                </tbody>
              </table>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      {modalPayment
      && (
        <ModalWrapper wrapperClass="smallModal" isOpen={!!modalPayment} setIsOpen={() => setModalPayment(undefined)}>
          <PaymentModal payment={modalPayment} />
        </ModalWrapper>
      )}
    </>
  );
};

export default PaymentsTable;
