import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonSlide,
  IonSlides,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { starOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { AppContextState } from '../../../../state/AppContext';
import { userProfileImage } from '../../../../utils';

interface ComponentProps {}

const ClientCommunity: React.FC<ComponentProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const contextState = useContext(AppContextState);
  const { clientCommunityResults } = contextState;

  const [slideOpts, setSlideOpts] = useState({
    initialSlide: 0,
    speed: 400,
    slidesPerView: `${Math.floor(window.innerWidth / 298)}.3`,
    spaceBetween: 1,
  });

  function changeSlideOpts(key: string, value: string) {
    setSlideOpts({
      ...slideOpts,
      [key]: parseFloat(value),
    });
  }

  window.onresize = () => {
    changeSlideOpts('slidesPerView', `${Math.floor(window.innerWidth / 298)}.3`);
  };

  const navigateToExpert = (id: number) => {
    history.push(`/user/request_expert/${id}`);
  };

  if (!clientCommunityResults || clientCommunityResults.length <= 0) {
    return (
      <div className="col-12">
        <p>{t('CC_L1')}</p>
      </div>
    );
  }

  return (
    <div className="ExpertsResultsContainer ion-margin-top">
      <div className="section-title text-center">
        <h2>{t('clientExpertCommunity')}</h2>
        <span className="title-border" />
      </div>
      <IonSlides data-testid="community-slider" options={slideOpts} pager scrollbar>
        {clientCommunityResults.map((expert, index) => {
          const rate: any = expert.review_rate ? expert.review_rate : 0;
          return (
            <IonSlide
              key={expert.uuid}
              id={expert.uuid}
              className="ion-margin-bottom"
            >
              <IonCard className="ion-margin-bottom">
                <img
                  className="carfull-image"
                  src={userProfileImage('expert', expert.uuid)}
                  alt="Expert Avatar"
                />
                <IonCardHeader>
                  <IonCardTitle>
                    {expert.location ? expert.location : t('EC_L2')}
                    {rate > 3 && (
                      <div className="rate">
                        {parseFloat(rate).toFixed(1)}
                        <IonIcon icon={starOutline} color="tertiary" slot="end" />
                      </div>
                    )}
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <div
                    className="ion-margin-bottom"
                    style={{ fontSize: '1rem' }}
                  >
                    {expert.first_name}
                    {' '}
                    {expert.last_name.charAt(0)}
                  </div>
                  <IonButton
                    className="ion-margin-bottom buttonAction"
                    color="primary"
                    shape="round"
                    expand="full"
                    size="small"
                    onClick={() => history.push(`/user/request_service/${expert.id}`)}
                  >
                    {t('serviceModal')}
                  </IonButton>
                  <IonButton
                    data-testid={`expert-modal-btn-${index}`}
                    className="buttonAction"
                    color="primary"
                    shape="round"
                    expand="full"
                    fill="outline"
                    size="small"
                    onClick={() => navigateToExpert(expert.id)}
                  >
                    {t('EC_L1')}
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonSlide>
          );
        })}
      </IonSlides>
    </div>
  );
};

export default ClientCommunity;
