import {
  IonCol, IonGrid, IonRow, IonText,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../components/modalWrapper/ModalWrapper';
import AppointmentsList from '../../components/userProfile/AppointmentsList';
import ExpertBalances from '../../components/userProfile/ExpertBalances';
import useBaseInformation from '../../hooks/useBaseInformation';
import useExpertDashboard from '../../hooks/useExpertDashboard';
import useGetAllAppointments from '../../hooks/useGetAllAppointments';
import { AppContextState } from '../../state/AppContext';
import Comments from './components/expert/Comments';
import Earnings from './components/expert/Earnings';
import ProfileCompletition from './components/expert/ProfileCompletition';
import RequestSlider from './components/expert/RequestSlider';

const ExpertDashboard: React.FC = () => {
  const { t } = useTranslation();

  const [validateBaseInformation] = useBaseInformation();
  const [validateExpertDashboard] = useExpertDashboard();
  const { compareAppointments } = useGetAllAppointments();
  const contextState = useContext(AppContextState);
  const { allAppointments } = contextState;
  let appointmentsInterval: any;

  const [donationsModal, setDonationsModal] = useState<boolean>(false);

  useEffect(() => {
    validateBaseInformation();
    validateExpertDashboard();
  }, []);

  useEffect(() => {
    if (allAppointments && !appointmentsInterval) {
      appointmentsInterval = setInterval(() => compareAppointments(allAppointments), 25000);
    }
    return () => clearInterval(appointmentsInterval);
  }, [allAppointments]);

  return (
    <div id="dashboard">
      <h1>{t('edTitle')}</h1>
      <RequestSlider />
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeLg="6">
            <div className="section-title">
              <h2>{t('edProfile')}</h2>
              <span className="title-border" />
            </div>
            <ProfileCompletition />
          </IonCol>
          <IonCol size="12" sizeLg="6" style={{ overflow: 'hidden' }}>
            <div className="section-title">
              <h2>{t('edComments')}</h2>
              <span className="title-border" />
            </div>
            <Comments />
          </IonCol>
        </IonRow>
      </IonGrid>
      <AppointmentsList />
      <IonRow className="ion-align-items-center ion-margin-bottom">
        <IonCol size="12" sizeLg="8">
          <Earnings />
        </IonCol>
        <IonCol size="12" sizeLg="4">
          <button
            className="btn-donations"
            type="button"
            onClick={() => setDonationsModal(true)}
          >
            <p className="header">{t('DC_L26')}</p>
            <p className="subHeader">{t('DC_L27')}</p>
          </button>
        </IonCol>
      </IonRow>
      <ExpertBalances />
      <ModalWrapper
        isOpen={donationsModal}
        setIsOpen={() => setDonationsModal(false)}
      >
        <IonText id="donationsContainer" className="ion-padding">
          <h2>{t('DC_L28')}</h2>
          <div className="donationsImages">
            <div className="images">
              <img src="assets/images/World_Vision.png" alt="World Vision" />
              <img src="assets/images/Red_Cross.png" alt="Red Cross" />
            </div>
          </div>
          <p
            className="donationsText text-left"
            dangerouslySetInnerHTML={{ __html: t('DC_L25') }}
          />
        </IonText>
      </ModalWrapper>
    </div>
  );
};

export default ExpertDashboard;
