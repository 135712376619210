/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  IonAvatar,
  IonButton, IonChip, IonContent, IonIcon,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import useFormatDate from '../../hooks/useFormatDate';
import { ClientPaymentDetail } from '../../interfaces';
import { requestInvoice } from '../../services/client';
import { AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import './PaymentModal.scss';

interface ComponentProps {
  payment: ClientPaymentDetail;
}

const PaymentModal: React.FC<ComponentProps> = ({ payment }) => {
  const { t } = useTranslation();
  const { formatIsoDate } = useFormatDate();
  const contextState = useContext(AppContextState);
  const { profile, services } = contextState;
  const { user_profile } = profile;

  const [requestInvoiceState, setRequestInvoiceState] = useState<
    string | undefined
  >(undefined);

  const userType = user_profile.type === 'Client' ? 'expert' : 'client';
  const userUid = user_profile.type === 'Client'
    ? payment?.appointments[0]?.expert_uid
    : payment?.appointments[0]?.client_uid;

  const returnService = () => {
    const service = services
      && services.find((o) => o.id === payment.appointments[0].service_id);
    return service ? service.type : '';
  };

  const requestUserInvoice = (id: number | undefined) => {
    if (id) {
      const jwtToken = sessionStorage.getItem('jwt') || '';
      requestInvoice(jwtToken, id)
        .then(() => setRequestInvoiceState('ready'))
        .catch(() => setRequestInvoiceState('error'));
    } else {
      setRequestInvoiceState('error');
    }
  };

  return (
    <IonContent>
      <div className="ion-margin-bottom">
        <div className="header">
          <h4>{t('paymentModalHeader')}</h4>
        </div>
        <div className="userImage expert">
          <IonAvatar className="userProfileImage">
            <img
              src={userProfileImage(userType, userUid)}
              alt="user"
            />
          </IonAvatar>
          <p>
            <span>{payment.appointments[0].expert_full_name}</span>
          </p>
        </div>
        <p className="message">
          <span className="label">{t('service')}</span>
          <span>{returnService()}</span>
        </p>
        <p className="message">
          <span className="label">{t('DC_L11')}</span>
          {payment.appointments[0].type === 'CancelledAppointment' ? (
            <span>{t('payCancelled')}</span>
          ) : payment.appointments[0].type === 'OpenAppointment' ? (
            <span>{t('payOpen')}</span>
          ) : payment.appointments[0].type === 'ScheduledAppointment' ? (
            <span>{t('payScheduled')}</span>
          ) : payment.appointments[0].type === 'InProgressAppointment' ? (
            <span>{t('payInprogress')}</span>
          ) : payment.appointments[0].type === 'ClosedAppointment' ? (
            <span>{t('payClosed')}</span>
          ) : (
            <span>{t('payCompleted')}</span>
          )}
        </p>
        <p className="message">
          <span className="label">{t('date')}</span>
          <span>
            {formatIsoDate(payment.appointments[0].start_date, 'DDD')}
          </span>
        </p>
        <p className="message">
          <span className="label">{t('startAt')}</span>
          <span>
            {formatIsoDate(payment.appointments[0].start_date, 'hh:mm a')}
          </span>
        </p>
        <p className="message">
          <span className="label">{t('endAt')}</span>
          <span>
            {formatIsoDate(payment.appointments[0].end_date, 'hh:mm a')}
          </span>
        </p>
        <div className="title text-center my-4">
          <p className="h3 m-0">{t('pay')}</p>
        </div>
        <p className="message">
          <span className="label">{t('payLabel')}</span>
          <span>{payment.paid ? t('CP_L7') : t('CP_L8')}</span>
        </p>
        <p className="message">
          {payment.payment_package ? (
            <span className="label">
              {t('SA_L20_3')}
              {' '}
              (
              {payment.appointments.length}
              {' '}
              {t('generalServices')}
              )
            </span>
          ) : (
            <span className="label">{t('SA_L20')}</span>
          )}
          <span>
            {payment.invoice?.amount || 0}
            {' '}
            USD
          </span>
        </p>
        {requestInvoiceState === 'ready' && (
        <IonChip color="success ion-margin-top">
          <IonIcon icon={checkmarkCircleOutline} />
          {t('requestSuccess')}
        </IonChip>
        )}
        {requestInvoiceState === 'error' && (
          <IonChip color="warning ion-margin-top">
            <IonIcon icon={alertCircleOutline} className="ion-margin-end" />
            {t('requestFailed')}
          </IonChip>
        )}
        {payment.invoice && payment.invoice.pdf_invoice_url ? (
          <IonButton
            data-testid="test-download-invoice"
            href={payment.invoice.pdf_invoice_url}
            target="_blank"
            rel="external noopener noreferrer"
            shape="round"
            color="secondary"
            fill="solid"
            className="ion-margin-top"
          >
            {t('downloadInvoice')}
          </IonButton>
        ) : (
          <IonButton
            data-testid="test-request-invoice"
            shape="round"
            color="secondary"
            fill="solid"
            className="ion-margin-top"
            onClick={() => requestUserInvoice(payment.invoice?.id || undefined)}
          >
            {t('requestInvoice')}
          </IonButton>
        )}
      </div>
    </IonContent>
  );
};

export default PaymentModal;
