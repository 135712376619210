// istanbul ignore file
/* eslint-disable react/jsx-props-no-spreading */
import {
  IonButton,
  IonCheckbox,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react';
import { briefcaseOutline, telescopeOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import { LOADER } from '../../../constants/constants';
import useLoader from '../../../hooks/useLoader';
import { General, Logintype } from '../../../interfaces';
import {
  emailValidation,
  validationResponse,
} from '../../../interfaces/loginInterfaces';
import {
  loginUser,
  registerUser,
  validateEmail,
} from '../../../services/client';
import { AppContextDispatch } from '../../../state/AppContext';

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  setKeepLoged: (data: boolean) => void;
  keepLoged: boolean;
  // eslint-disable-next-line no-unused-vars
  loginUserCallback: (user: Logintype) => void;
}

export type LoginFormData = {
  userEmail: string;
  verificationCode?: string;
  firstName?: string;
  lastName?: string;
};

const LoginForm: React.FC<ComponentProps> = ({
  keepLoged,
  setKeepLoged,
  loginUserCallback,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LoginFormData>();
  const { setLoader } = useLoader();

  const contextDispatch = useContext(AppContextDispatch);
  const [emailExist, setEmailExist] = useState<boolean | undefined>(undefined);
  const [sendButton, setSendButton] = useState('1stSend');
  const [error, setError] = useState<string | undefined>(undefined);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [modalSelector, setModalSelector] = useState(false);
  const [loginResponse, setLoginResponse] = useState<
    validationResponse | undefined
  >(undefined);

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const onSubmit = (form: LoginFormData) => {
    setError(undefined);
    const {
      userEmail, verificationCode, firstName, lastName,
    } = form;
    setLoader(true);
    if (emailExist === undefined && userEmail) {
      const toValidate: emailValidation = {
        email: userEmail,
        user_type: 'client',
      };
      validateEmail(toValidate)
        .then((response) => {
          const { data } = response;
          setEmailExist(data.message === 'auth code has been sent');
          setEmailInvalid(data.message === 'email does not exist');
          setLoader(false);
        })
        .catch(() => {
          setError('DC_L22');
          setLoader(false);
        });
    } else if (emailExist === false && firstName && lastName) {
      registerUser({ user: userEmail, firstName, lastName })
        .then(({ data }) => {
          console.log(data);
          setLoader(false);
        })
        .catch(() => {
          setError('DC_L22');
          setLoader(false);
        });
    } else if (emailExist && verificationCode) {
      loginUser({
        auth_code: verificationCode,
        user_type: 'Client',
        email: userEmail,
      })
        .then(({ data }) => {
          const { client, expert } = data as unknown as validationResponse;
          setLoginResponse(data);
          setLoader(false);
          if (client && expert && client.id && expert.id) { setModalSelector(true); } else if (client && client.id) setUserType(client, 'Client');
          else if (expert && expert.id) setUserType(expert, 'Expert');
        })
        .catch(() => {
          setError('DC_L22');
          setLoader(false);
        });
    }
  };

  const setUserType = (user: Logintype, type: string) => {
    const userFixed = { ...user };
    userFixed.user_type = type;
    if (type === 'Client') userFixed.provider = 'facebook';
    else userFixed.provider = 'linkedin';
    dispatchLoader(true);
    loginUserCallback(userFixed);
  };

  useEffect(() => {
    if (emailExist === undefined) {
      reset();
      setEmailInvalid(false);
    }
  }, [emailExist]);

  return (
    <>
      <h1>
        {t(
          // eslint-disable-next-line no-nested-ternary
          emailExist
            ? 'LoginTitle'
            : emailExist === undefined
              ? 'LoginTitle'
              : 'RegisterTitle',
        )}
      </h1>
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        {emailExist && (
          <IonText color="success">{t('LoginEmailExist')}</IonText>
        )}
        {error && <IonChip color="danger">{t(error)}</IonChip>}
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              {emailInvalid && (
                <IonText color="warning">
                  <p style={{ textAlign: 'center', marginBottom: '0px' }}>
                    {t('emailVerifiedFalse')}
                  </p>
                  <p style={{ textAlign: 'center', marginTop: '0px' }}>
                    {t('emailVerifiedFalseQuestion')}
                  </p>
                </IonText>
              )}
            </IonCol>
            <IonCol
              size="12"
              className="form__group field"
              style={{ height: 'auto' }}
            >
              <input
                data-testid="test-input-email"
                autoComplete="off"
                placeholder="email"
                id="userEmail"
                className={`form__field ${errors.userEmail && 'invalid'}`}
                type="email"
                {...register('userEmail', {
                  required: true,
                  pattern: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                })}
              />
              <label htmlFor="userEmail" className="form__label">
                {t('formEmail')}
              </label>
              {errors.userEmail && errors.userEmail.type === 'required' && (
                <IonText color="danger">{t('mailInvalid')}</IonText>
              )}
              {errors.userEmail && errors.userEmail.type === 'pattern' && (
                <IonText color="danger">{t('mailPattern')}</IonText>
              )}
            </IonCol>
            {emailExist && (
              <IonCol
                size="12"
                className="form__group field"
                style={{ height: 'auto' }}
              >
                <input
                  data-testid="test-input-pass"
                  className={`form__field ${
                    errors.verificationCode && 'invalid'
                  }`}
                  placeholder="verification"
                  id="verificationLogin"
                  type="text"
                  {...register('verificationCode', { required: true })}
                />
                <label htmlFor="verificationLogin" className="form__label">
                  {t('verification')}
                </label>
                {errors.verificationCode && (
                  <IonText color="danger">{t('verificationInvalid')}</IonText>
                )}
              </IonCol>
            )}
            {emailExist === false && (
              <>
                <IonCol
                  size="12"
                  className="form__group field"
                  style={{ height: 'auto' }}
                >
                  <input
                    data-testid="test-input-pass2"
                    className={`form__field ${errors.firstName && 'invalid'}`}
                    placeholder="formUserName"
                    id="firtsName"
                    type="text"
                    {...register('firstName', {
                      required: true,
                    })}
                  />
                  <label htmlFor="firtsName" className="form__label">
                    {t('formUserName')}
                  </label>
                  {errors.firstName && errors.firstName.type === 'required' && (
                    <IonText color="danger">{t('firstNameInvalid')}</IonText>
                  )}
                </IonCol>
                <IonCol
                  size="12"
                  className="form__group field"
                  style={{ height: 'auto' }}
                >
                  <input
                    data-testid="test-input-passC"
                    autoComplete="new-verification"
                    id="lastName"
                    className={`form__field ${errors.lastName && 'invalid'}`}
                    placeholder="Password Confirm"
                    type="verification"
                    {...register('lastName', {
                      required: true,
                    })}
                  />
                  <label htmlFor="lastName" className="form__label">
                    {t('formUserLastname')}
                  </label>
                  {errors.lastName && errors.lastName.type === 'required' && (
                    <IonText color="danger">{t('lastnameInvalid')}</IonText>
                  )}
                </IonCol>
              </>
            )}
            <IonCol size="12">
              {emailExist !== undefined && (
                <IonButton
                  color="primary"
                  size="small"
                  fill="clear"
                  style={{ fontSize: '0.7rem' }}
                  onClick={() => {
                    setEmailExist(undefined);
                    setSendButton('1stSend');
                  }}
                >
                  {t('yesAccount')}
                  &nbsp;
                  <IonText color="secondary">{t('signIn')}</IonText>
                </IonButton>
              )}
              {emailExist === undefined && (
                <IonButton
                  color="primary"
                  size="small"
                  fill="clear"
                  style={{ fontSize: '0.7rem' }}
                  onClick={() => {
                    setEmailExist(false);
                    setSendButton('3rdSend');
                  }}
                >
                  {t('noAccount')}
                  &nbsp;
                  <IonText color="secondary">{t('signUp')}</IonText>
                </IonButton>
              )}
              <IonList className="ion-no-padding" lines="none">
                <IonItem>
                  <IonCheckbox
                    className="keeploged-checkbox"
                    mode="ios"
                    color="primary"
                    checked={keepLoged}
                    onIonChange={(e) => setKeepLoged(e.detail.checked)}
                    slot="start"
                  />
                  <IonLabel className="keeploged-checkbox-message">
                    {t('keepLog')}
                  </IonLabel>
                </IonItem>
              </IonList>
              <IonButton
                data-testid="test-input-submit"
                shape="round"
                fill="outline"
                color="secondary"
                type="submit"
                expand="block"
              >
                {t(sendButton)}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
      <ModalWrapper
        wrapperClass="borderModal smallModal"
        isOpen={modalSelector}
        setIsOpen={() => setModalSelector(false)}
      >
        <IonContent>
          <h3 style={{ marginTop: '2rem' }}>{t('userLoginBothTypesTitle')}</h3>
          <div
            className="ion-padding"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {t('userLoginBothTypesBody')}
            {loginResponse && (
              <div style={{ marginTop: '2rem' }}>
                <IonButton
                  expand="block"
                  onClick={() => setUserType(loginResponse.expert, 'Expert')}
                >
                  <IonIcon slot="start" icon={briefcaseOutline} />
                  <IonText>{t('Expert')}</IonText>
                </IonButton>
                <IonButton
                  style={{ marginTop: '2rem' }}
                  expand="block"
                  onClick={() => setUserType(loginResponse.client, 'Client')}
                >
                  <IonIcon slot="start" icon={telescopeOutline} />
                  <IonText>{t('Client')}</IonText>
                </IonButton>
              </div>
            )}
          </div>
        </IonContent>
      </ModalWrapper>
    </>
  );
};

export default LoginForm;
