/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  calendarNumberOutline,
  informationCircleOutline,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { AppointmentDetail } from '../../interfaces/clientInterfaces';
import { AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import useFormatDate from '../../hooks/useFormatDate';
import './AppoinmentsList.scss';
import AppoinmentModal from './AppoinmentModal';
import ModalWrapper from '../modalWrapper/ModalWrapper';
import UpdateAppointment from './UpdateAppointment';

type SelectableSegments = string | undefined;
type AppointmentsType = AppointmentDetail[] | undefined;
type ValidEventTypes = 'accept' | 'reject' | 'reschedule';

const AppointmentsList: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formatIsoDate } = useFormatDate();

  const contextState = useContext(AppContextState);
  const { allAppointments, profile, services } = contextState;
  const { user_profile } = profile;

  const [activeSegment, setActiveSegment] = useState<SelectableSegments>('allAppointments');
  const [open, setOpen] = useState<AppointmentsType>(undefined);
  const [cancelled, setCancelled] = useState<AppointmentsType>(undefined);
  const [scheduled, setScheduled] = useState<AppointmentsType>(undefined);
  const [inProgress, setInProgress] = useState<AppointmentsType>(undefined);
  const [closed, setClosed] = useState<AppointmentsType>(undefined);
  const [completed, setCompleted] = useState<AppointmentsType>(undefined);
  const [appointmentModal, setAppointmentModal] = useState<
    AppointmentDetail | undefined>(undefined);
  const [appointmentUpdate, setAppointmentUpdate] = useState<AppointmentDetail>();
  const [updateEvent, setUpdateEvent] = useState<ValidEventTypes>();

  useEffect(() => {
    sortAppointments();
  }, [allAppointments]);

  /* istanbul ignore next */
  const changeModalToReschedule = (type: ValidEventTypes) => {
    setUpdateEvent(undefined);
    setTimeout(() => {
      setUpdateEvent(type);
      setAppointmentUpdate(appointmentUpdate);
    }, 500);
  };

  /* istanbul ignore next */
  const closeUpdateModal = () => {
    setAppointmentUpdate(undefined);
    setUpdateEvent(undefined);
  };

  const sortAppointments = () => {
    setCancelled([]);
    setOpen([]);
    setScheduled([]);
    setInProgress([]);
    setClosed([]);
    setCompleted([]);
    const openAppointments: AppointmentDetail[] = [];
    const scheduledAppointments: AppointmentDetail[] = [];
    const cancelledAppointments: AppointmentDetail[] = [];
    const inprogressAppointments: AppointmentDetail[] = [];
    const closedAppointments: AppointmentDetail[] = [];
    const completedAppointments: AppointmentDetail[] = [];
    allAppointments?.sort(
      (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
    );
    allAppointments?.forEach((element) => {
      if (element.type === 'CancelledAppointment') {
        cancelledAppointments.push(element);
      } else if (element.type === 'OpenAppointment') {
        openAppointments.push(element);
      } else if (element.type === 'ScheduledAppointment') {
        scheduledAppointments.push(element);
      } else if (element.type === 'InProgressAppointment') {
        inprogressAppointments.push(element);
      } else if (element.type === 'ClosedAppointment') {
        closedAppointments.push(element);
      } else if (element.type === 'CompletedAppointment') {
        completedAppointments.push(element);
      }
    });
    setTimeout(() => {
      setCancelled(cancelledAppointments);
      setOpen(openAppointments);
      setScheduled(scheduledAppointments);
      setInProgress(inprogressAppointments);
      setClosed(closedAppointments);
      setCompleted(completedAppointments);
    }, 700);
  };

  const renderQuestions = (event: AppointmentDetail) => {
    const questions = event.service_request;
    return Object.entries(questions).map((element, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <IonCol size="12" key={index}>
        <IonCard className="ion-text-left">
          <IonCardContent>
            <h5>{t(element[0])}</h5>
            {element[1]}
          </IonCardContent>
        </IonCard>
      </IonCol>
    ));
  };

  const renderCancelBody = (event: AppointmentDetail) => {
    const cancellBy = event.cancelled_by;
    return (
      <IonCol size="12">
        <IonCard className="ion-text-left">
          <IonCardContent>
            <IonText>
              <p>
                {t('DC_L12')}
                &nbsp;
                {event.cancel_reason ? event.cancel_reason : ''}
              </p>
              <p>
                {t('DC_L12_B')}
                &nbsp;
                {event
                  && event.cancelled_on
                  && formatIsoDate(event.cancelled_on)}
              </p>
              <p>
                {t('DC_L12_C')}
                &nbsp;
                {cancellBy === 'systemUser'
                  ? t('systemUser')
                  : cancellBy && parseInt(cancellBy, 10) === event.user_id
                    ? t('SearchExpert')
                    : t('Expert')}
              </p>
            </IonText>
          </IonCardContent>
        </IonCard>
      </IonCol>
    );
  };

  const renderAppoinmentButton = (event: AppointmentDetail) => {
    const end = DateTime.fromISO(event.end_date);
    const endControl = end.diff(DateTime.now());
    if (event.type === 'CancelledAppointment') {
      return (
        <IonButton
          shape="round"
          size="small"
          onClick={() => history.push(`/user/service_meeting/${event.id}`)}
        >
          {t('cancelled')}
        </IonButton>
      );
    }
    if (
      event.type === 'CompletedAppointment'
      || event.type === 'ClosedAppointment'
    ) {
      return (
        <IonButton
          shape="round"
          size="small"
          onClick={() => history.push(`/user/service_meeting/${event.id}`)}
        >
          {t('closed')}
        </IonButton>
      );
    }
    if (event.type === 'ScheduledAppointment') {
      return (
        <IonButton
          shape="round"
          color="primary"
          expand="block"
          onClick={() => history.push(`/user/service_meeting/${event.id}`)}
        >
          {endControl.milliseconds > 0
            ? formatIsoDate(event.start_date, 'ff', 'en')
            : t('notTaken')}
        </IonButton>
      );
    }
    if (event.type === 'InProgressAppointment') {
      return (
        <IonButton
          shape="round"
          color="primary"
          expand="block"
          // TODO: regresar a control de bloqueo de boton
          // disabled={!(endControl.milliseconds > 0)}
          onClick={() => {
            // if (endControl.milliseconds > 0) history.push(`/user/service_meeting/${event.id}`);
            history.push(`/user/service_meeting/${event.id}`);
          }}
        >
          {endControl.milliseconds > 0 ? t('enter') : t('notTaken')}
        </IonButton>
      );
    }
    if (event.type === 'OpenAppointment' && endControl.milliseconds < 0) {
      return (
        <IonButton shape="round" color="primary" expand="block" disabled>
          {t('notTaken')}
        </IonButton>
      );
    }
    if (event.type === 'OpenAppointment' && endControl.milliseconds > 0) {
      return (
        <>
          <IonButton
            color="secondary"
            fill="outline"
            shape="round"
            expand="block"
            className="ion-no-padding reschedule-button"
            data-testid="test-request-reschedule"
            style={{ position: 'absolute', right: '0.5rem', top: '-2.5rem' }}
            onClick={() => {
              setAppointmentUpdate(event);
              setUpdateEvent('reschedule');
            }}
          >
            <IonIcon
              className="reschedule-icon"
              slot="icon-only"
              icon={calendarNumberOutline}
            />
          </IonButton>
          {user_profile.type !== 'Client' && (
            <IonButton
              data-testid="test-request-accept"
              shape="round"
              color="primary"
              expand="block"
              onClick={() => {
                setAppointmentUpdate(event);
                setUpdateEvent('accept');
              }}
            >
              {t('accept')}
            </IonButton>
          )}
          <IonButton
            data-testid="test-request-reject"
            className="ion-margin-vertical"
            shape="round"
            fill="outline"
            expand="block"
            onClick={() => {
              setAppointmentUpdate(event);
              setUpdateEvent('reject');
            }}
          >
            {t('reject')}
          </IonButton>
        </>
      );
    }
    return null;
  };

  const appointmentCard = (
    appointmentArray: AppointmentsType,
    isCancel: boolean = false,
  ) => {
    if (appointmentArray && appointmentArray.length > 0) {
      return appointmentArray.map((appointment) => {
        const user = user_profile.type === 'Client'
          ? appointment.expert
          : appointment.client;
        const userType = user_profile.type === 'Client' ? 'expert' : 'client';
        const service = services
          ? services.find((o) => o.id === appointment.service_id)
          : undefined;
        return (
          <IonCol
            data-testid="test-card-cointaner"
            className="appoinmentCard"
            key={appointment.id}
            size="12"
          >
            <IonCard>
              <IonGrid className="ion-padding-vertical">
                <IonRow className="ion-align-items-center">
                  <IonCol size="12" sizeLg="2">
                    <IonIcon
                      className="informationIcon"
                      color="secondary"
                      icon={informationCircleOutline}
                      onClick={() => setAppointmentModal(appointment)}
                    />
                    <IonAvatar
                      style={{
                        width: '120px',
                        height: '120px',
                        margin: 'auto',
                      }}
                    >
                      <img
                        src={userProfileImage(userType, user.uid ? user.uid : user.uuid ? user.uuid : '')}
                        alt="user"
                      />
                    </IonAvatar>
                  </IonCol>
                  <IonCol size="12" sizeLg="8" className="">
                    <IonText className="appoinmentHead h6 ion-padding-horizontal">
                      <span>{`${user.first_name} ${user.last_name}`}</span>
                      <span>
                        {service
                          ? service.type.includes('xpress')
                            ? t('express_service')
                            : t(`${service.type.toLowerCase()}_service`)
                          : ''}
                      </span>
                      {!appointment.is_reschedule && (
                        <span>{formatIsoDate(appointment.start_date)}</span>
                      )}
                      {appointment.is_reschedule && (
                        <div className="rescheduleDateContainer">
                          <span
                            className={`${
                              appointment.is_reschedule ? ' cancelled ' : ''
                            }`}
                          >
                            {formatIsoDate(appointment.start_date)}
                          </span>
                          <span className="rescheduleNew">
                            {formatIsoDate(appointment.reschedule_date)}
                          </span>
                        </div>
                      )}
                    </IonText>
                    <IonRow className="questions ion-hide-md-down">
                      {isCancel
                        ? renderCancelBody(appointment)
                        : renderQuestions(appointment)}
                    </IonRow>
                  </IonCol>
                  <IonCol size="12" sizeLg="2" className="ion-margin-top">
                    {renderAppoinmentButton(appointment)}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </IonCol>
        );
      });
    }
    return <IonCol size="12">{t('noAppText')}</IonCol>;
  };

  if (!allAppointments) return null;

  return (
    <>
      <div className="section-title">
        <h2>{t('DC_L6')}</h2>
        <span className="title-border" />
      </div>
      <div
        data-testid="test-list-container"
        id="appoinmentsList"
        className="ion-padding"
      >
        <IonSegment
          scrollable
          value={activeSegment}
          onIonChange={(e) => setActiveSegment(e.detail.value)}
        >
          <IonSegmentButton
            data-testid="allAppointments"
            value="allAppointments"
          >
            <IonLabel>{t('apTabAll')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            data-testid="pastAppoinmnets"
            value="pastAppoinmnets"
          >
            <IonLabel>{t('apTabPast')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            data-testid="cancellCappoinmnets"
            value="cancellCappoinmnets"
          >
            <IonLabel>{t('apTabCancel')}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonGrid id="appSlider" className="ion-margin-top">
          <IonRow>
            {activeSegment === 'allAppointments' && (
              <>
                <p className="h5 ion-no-margin">{t('appTodayTitle')}</p>
                {inProgress && appointmentCard(inProgress)}
                <p className="h5 ion-no-margin">{t('appNextTitle')}</p>
                {scheduled && appointmentCard(scheduled)}
                <p className="h5 ion-no-margin">{t('edRequests')}</p>
                {open && appointmentCard(open)}
              </>
            )}
            {activeSegment === 'pastAppoinmnets' && (
              <>
                {completed && appointmentCard(completed)}
                {closed && appointmentCard(closed)}
              </>
            )}
            {activeSegment === 'cancellCappoinmnets'
              && cancelled
              && appointmentCard(cancelled, true)}
          </IonRow>
        </IonGrid>
      </div>
      {!!appointmentModal && (
        <ModalWrapper
          wrapperClass="bigModal"
          isOpen={!!appointmentModal}
          setIsOpen={() => setAppointmentModal(undefined)}
          closeBtnProps={{ className: 'closeBtn', color: 'primary' }}
        >
          <AppoinmentModal appointmentDetail={appointmentModal} />
        </ModalWrapper>
      )}
      {!!appointmentUpdate && !!updateEvent && (
        <ModalWrapper
          wrapperClass={`update-appointment-modal 
            ${
              updateEvent === 'reschedule'
              && appointmentUpdate.is_reschedule
              && 'bigModal'
            }`}
          isOpen={!!appointmentUpdate}
          setIsOpen={/* istanbul ignore next */ () => closeUpdateModal()}
        >
          <IonContent>
            <UpdateAppointment
              appoinment={appointmentUpdate}
              eventType={updateEvent}
              setEventType={
                /* istanbul ignore next */ (type: ValidEventTypes) => changeModalToReschedule(type)
              }
              closeModal={closeUpdateModal}
            />
          </IonContent>
        </ModalWrapper>
      )}
    </>
  );
};

export default AppointmentsList;
