/* eslint-disable no-nested-ternary */
import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
} from '@ionic/react';
import { star } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../hooks/useFormatDate';
import { CommentsAndRates } from '../../../interfaces';
import { AppointmentDetail } from '../../../interfaces/clientInterfaces';
import { getUserRates } from '../../../services/client';
import { AppContextState } from '../../../state/AppContext';
import { userProfileImage } from '../../../utils';
import NewComments from './NewComments';
import SessionRequests from './SessionRequests';

interface ComponentProps {
  service: AppointmentDetail;
}

const ServiceDetails: React.FC<ComponentProps> = ({ service }) => {
  // console.log(service);
  const jwt: string = sessionStorage.getItem('jwt') || '';
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const { services } = contextState;

  const [serviceComments, setServiceComments] = useState<CommentsAndRates[]>(
    [],
  );
  const { formatIsoDate } = useFormatDate();
  // console.log(serviceComments);

  const renderComments = () => (
    <IonCol size="12">
      <IonCard className="ion-padding">
        <IonCardHeader className="section-title ion-text-start">
          <h2>{t('edComments')}</h2>
          <span className="title-border" />
        </IonCardHeader>
        {serviceComments?.length > 0
          ? serviceComments.map((comment) => (
            <IonCard key={comment.id} className="ion-padding">
              <IonCardHeader>
                <IonCardSubtitle style={{ display: 'flex', flexWrap: 'wrap' }} className="ion-justify-content-between">
                  <IonText style={{ minWidth: '200px' }}>
                    <IonText color="warning">
                      <IonIcon icon={star} />
                      {comment.rate}
                    </IonText>
                    &nbsp;
                    {comment.reviewer_name}
                  </IonText>
                  <IonText style={{ minWidth: '200px' }}>
                    <span className="comment-date">{formatIsoDate(comment.updated_at, 'DD hh:mm a')}</span>
                  </IonText>
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <p>{comment.review}</p>
              </IonCardContent>
            </IonCard>
          ))
          : t('edNoComments')}
        {
         (service.type !== 'OpenAppointment' && service.type !== 'ScheduledAppointment')
        && <NewComments service={service} completed={getServiceComments} />
        }
      </IonCard>
    </IonCol>
  );

  const getServiceComments = () => {
    getUserRates(jwt)
      .then(({ data }) => {
        if ((data as CommentsAndRates[]).length > 0) {
          const currentComments = (data as CommentsAndRates[]).filter(
            (comment) => comment.appointment_id === service.id,
          );
          setServiceComments(currentComments);
        }
      })
      .catch((error) => console.log(error));
  };

  const returnService = () => {
    const result = services
      && services.find((o) => o.id === service.service_id);
    return result ? result.type : '';
  };

  const renderServiceStatus = () => (
    <IonCol size="12" sizeLg="6" style={{ margin: '0 auto' }}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            {service.type === 'CancelledAppointment' ? (
              <span>{t('payCancelled')}</span>
            ) : service.type === 'OpenAppointment' ? (
              <span>{t('payOpen')}</span>
            ) : service.type === 'ScheduledAppointment' ? (
              <span>{t('payScheduled')}</span>
            ) : service.type === 'InProgressAppointment' ? (
              <span>{t('payInprogress')}</span>
            ) : service.type === 'ClosedAppointment' ? (
              <span>{t('payClosed')}</span>
            ) : (
              <span>{t('payCompleted')}</span>
            )}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p className="message ion-margin-vertical">
            <span className="label no-float">{t('service')}</span>
            <span>{returnService()}</span>
          </p>
          <p className="message ion-margin-vertical">
            <span className="label no-float">{t('date')}</span>
            <span>
              {formatIsoDate(service.start_date, 'DDD')}
            </span>
          </p>
          <p className="message ion-margin-vertical">
            <span className="label no-float">{t('startAt')}</span>
            <span>
              {formatIsoDate(service.start_date, 'hh:mm a')}
            </span>
          </p>
          <p className="message ion-margin-vertical">
            <span className="label no-float">{t('endAt')}</span>
            <span>
              {formatIsoDate(service.end_date, 'hh:mm a')}
            </span>
          </p>
          {
            service.is_reschedule
            && (
              <>
                <IonText color="secondary">
                  <span className="label no-float">{t('reschedule_motive')}</span>
                </IonText>
                <p className="message ion-margin-vertical">
                  <span>
                    {service.reschedule_motive}
                  </span>
                </p>
                <p className="message ion-margin-vertical">
                  <span className="label no-float">{t('reschedule_date')}</span>
                  <span>
                    {formatIsoDate(service.reschedule_date, 'DDD')}
                  </span>
                </p>
              </>
            )
          }
          {
            (service.cancelled_on && service.cancelled_by)
            && (
              <>
                <IonText color="secondary">
                  <span className="label no-float">{t('DC_L12')}</span>
                </IonText>
                <p className="message ion-margin-vertical">
                  <span>
                    {service.cancel_reason}
                  </span>
                </p>
                <p className="message ion-margin-vertical">
                  <span className="label no-float">{t('DC_L12_B')}</span>
                  <span>
                    {formatIsoDate(service.cancelled_on, 'DDD')}
                  </span>
                </p>
              </>
            )
          }
        </IonCardContent>
      </IonCard>
    </IonCol>
  );

  useEffect(() => {
    getServiceComments();
  }, []);

  return (
    <>
      <IonRow>
        <IonCol size="12" sizeLg="6">
          <IonCard>
            <IonAvatar className="rounded-circle userProfileImage">
              <img
                src={userProfileImage('Client' || '', service.client.uuid || '')}
                alt="user"
              />
            </IonAvatar>
            <IonCardHeader>
              <IonCardSubtitle className="ion-text-center">
                {t('SearchExpert')}
              </IonCardSubtitle>
              <IonCardTitle>
                <h3 className="text-center">
                  {service.client.first_name}
                  {' '}
                  {service.client.last_name}
                </h3>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p className="ion-text-justify">
                {service.client.about.aboutBody}
              </p>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeLg="6">
          <IonCard>
            <IonAvatar className="rounded-circle userProfileImage">
              <img
                src={userProfileImage('Expert' || '', service.expert.uuid || '')}
                alt="user"
              />
            </IonAvatar>
            <IonCardHeader>
              <IonCardSubtitle className="ion-text-center">
                {t('Expert')}
              </IonCardSubtitle>
              <IonCardTitle>
                <h3 className="text-center">
                  {service.expert.first_name}
                  {' '}
                  {service.expert.last_name}
                </h3>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p className="ion-text-justify">
                {service.expert.about.aboutBody}
              </p>
            </IonCardContent>
          </IonCard>
        </IonCol>
        {renderServiceStatus()}
        {renderComments()}
      </IonRow>
      <SessionRequests service={service} />
    </>
  );
};

export default ServiceDetails;
