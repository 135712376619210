/* eslint-disable camelcase */
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import ErrorModal from '../../components/error/ErrorModal';
import ModalWrapper from '../../components/modalWrapper/ModalWrapper';
import ExpertServicesSelect from '../../components/serviceSelect/ExpertServicesSelect';
import Success from '../../components/success/Success';
import RequestExpertAvailabilities from '../../components/userProfile/RequestExpertAvailabilities';
import useBaseInformation from '../../hooks/useBaseInformation';
import useLoader from '../../hooks/useLoader';
import useServicePayment from '../../hooks/useServicePayment';
import { ServicesBase, Sethistorysearch } from '../../interfaces';
import { ExpertDetail } from '../../interfaces/clientInterfaces';
import { getExpertsByID } from '../../services/client';
import { AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import CoachingQuestions from './components/CoachingQuestions';
import ExpressQuestions from './components/ExpressQuestions';
import MentoringQuestions from './components/MentoringQuestions';
import SessionCards from './components/SessionCards';
import SessionsAmmount from './components/SessionsAmmount';
import Step2Header from './components/Step2Header';
import Step3 from './components/Step3';

type allowedServices = 'expressCheck' | 'mentoringCheck' | 'coachingCheck';

type FormData = {
  coachType?: string;
  searchCoaching?: string;
  coachChanges?: string;
  coachAchives?: string;
  searchExpressAdvice?: string;
  searchMentory?: string;
  needMentory?: string;
};

interface ServicesRequest {
  start_date: string;
  end_date: string;
  type: string;
}

const RequestService: React.FC = () => {
  const jwt = sessionStorage.getItem('jwt') || '';
  const { t } = useTranslation();
  const history = useHistory();
  const { setAndRequestService, error, completed } = useServicePayment();
  const { id } = useParams<{ id: string }>();
  const { setLoader } = useLoader();
  const [validateBaseInformation] = useBaseInformation();
  const contextState = useContext(AppContextState);
  const { services } = contextState;

  const [expert, setExpert] = useState<ExpertDetail>();
  const [serviceSelected, setServiceSelected] = useState<allowedServices>();
  const [formResponse, setFormResponse] = useState<FormData>();
  const [activeService, setActiveService] = useState<ServicesBase>();
  const [step1, setStep1] = useState<boolean>(true);
  const [step2, setStep2] = useState<boolean>(false);
  const [step3, setStep3] = useState<boolean>(false);
  const [sessionsQuantity, setSessionsQuantity] = useState<string>('1');
  const [sessionsArray, setSessionsArray] = useState<ServicesRequest[]>([]);
  const [paymentToken, setPaymentToken] = useState<string>();
  const [searchHistory, setSearchHistory] = useState<Sethistorysearch>();
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const showExpertProfile = () => {
    setLoader(true);
    getExpertsByID(jwt, parseInt(id, 10)).then(({ data }) => {
      setExpert(data);
      setLoader(false);
    });
  };

  const completeStep1 = (data: object) => {
    setFormResponse(data);
    setStep1(false);
    setStep2(true);
  };

  const returnToStep1 = () => {
    setStep1(true);
    setStep2(false);
    setSessionsQuantity('1');
    setSessionsArray([]);
  };

  const completeStep2 = () => {
    setStep2(false);
    setStep3(true);
  };

  const returnToStep2 = () => {
    setStep2(true);
    setStep3(false);
  };

  const isDateInArray = (date: string) => {
    const newDate = DateTime.fromISO(date).toISO();
    return sessionsArray.some((current) => current.start_date === newDate);
  };

  const addToSessionArray = (date: string) => {
    if (!isDateInArray(date)) {
      const newDate = DateTime.fromISO(date).toISO();
      const newEnd = DateTime.fromISO(date)
        .plus({ minutes: activeService?.duration_in_min }).toISO();
      const newSessionsArray = sessionsArray;
      const newServiceObject:ServicesRequest = {
        start_date: newDate,
        end_date: newEnd,
        type: 'OpenAppointment',
      };
      if (sessionsArray.length === parseInt(sessionsQuantity, 10)) {
        newSessionsArray.pop();
        newSessionsArray.push(newServiceObject);
        const sorted = newSessionsArray
          .sort((a:ServicesRequest, b: ServicesRequest) => (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          ));
        setSessionsArray([...sorted]);
      } else if (sessionsArray.length > 1) {
        newSessionsArray.push(newServiceObject);
        const sorted = newSessionsArray
          .sort((a:ServicesRequest, b: ServicesRequest) => (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          ));
        setSessionsArray([...sorted]);
      } else {
        newSessionsArray.push(newServiceObject);
        setSessionsArray([...newSessionsArray]);
      }
    }
  };

  const deleteSessionFromArray = (position: number) => {
    const copiedArray = [...sessionsArray];
    copiedArray.splice(position, 1);
    setSessionsArray(copiedArray);
  };

  useEffect(() => {
    if (id) showExpertProfile();
    return () => {
      setExpert(undefined);
    };
  }, [id]);

  useEffect(() => {
    validateBaseInformation();
  }, []);

  useEffect(() => {
    if (serviceSelected && services) {
      const findService = serviceSelected.replace('Check', '');
      const newService = services
        .filter((service) => service.type.toLowerCase().includes(findService));
      setActiveService(newService[0]);
    }
  }, [serviceSelected, services]);

  useEffect(() => {
    const newLenght = parseInt(sessionsQuantity, 10);
    if (sessionsArray.length > newLenght) {
      setSessionsArray([...sessionsArray].slice(0, newLenght));
    }
  }, [sessionsQuantity]);

  useEffect(() => {
    if (searchHistory && paymentToken && formResponse && expert && serviceSelected) {
      setAndRequestService(
        searchHistory,
        paymentToken,
        formResponse,
        expert.id,
        sessionsArray,
        serviceSelected,
      );
    }
  }, [searchHistory, paymentToken, formResponse, expert, serviceSelected]);

  useEffect(() => {
    setErrorModal(error);
    setSuccessModal(completed);
  }, [error, completed]);

  return (
    <IonGrid className="controlled-width">
      <IonRow className="ion-justify-content-center">
        <IonCol size="6" className="text-center">
          <IonAvatar className="userProfileImage">
            <img
              src={userProfileImage('Expert', expert?.uuid || '')}
              alt="user"
            />
          </IonAvatar>
          <IonText color="primary">
            <h1 className="ion-no-margin">{expert?.first_name}</h1>
          </IonText>
        </IonCol>
      </IonRow>
      {step1 && (
        <IonRow>
          <IonCol size="12">
            <h3>{t('FM_L2')}</h3>
            <ExpertServicesSelect
              expert={expert}
              selected={(value: allowedServices) => setServiceSelected(value)}
            />
            {serviceSelected === 'expressCheck' && (
              <ExpressQuestions response={(data) => completeStep1(data)} />
            )}
            {serviceSelected === 'mentoringCheck' && (
              <MentoringQuestions response={(data) => completeStep1(data)} />
            )}
            {serviceSelected === 'coachingCheck' && (
              <CoachingQuestions response={(data) => completeStep1(data)} />
            )}
          </IonCol>
        </IonRow>
      )}
      {(step2 && activeService) && (
        <>
          <Step2Header
            sessions={sessionsQuantity}
            service={activeService}
            returnText="changeService"
            returnStep={() => returnToStep1()}
          />
            {activeService && activeService.type !== 'ExpressAdvice' && (
              <SessionsAmmount
                sessions={sessionsQuantity}
                setSessions={(session) => setSessionsQuantity(session)}
              />
            )}
          <div className={`collapsable-container ${sessionsArray.length === parseInt(sessionsQuantity, 10) ? 'collapse' : 'expand'}`}>
            {expert && (
              <>
                {sessionsArray.length > 1 && (
                <IonText color="tertiary">
                  {t('appPackagesCount', {
                    count: sessionsArray.length,
                    total: parseInt(sessionsQuantity, 10),
                  })}
                </IonText>
                )}
                <h4>{t('SA_L12')}</h4>
                <RequestExpertAvailabilities
                  eventExpert={expert.id}
                  selectedAvailabilitie={(data) => addToSessionArray(data)}
                />
              </>
            )}
          </div>
          {
            sessionsArray && (
            <SessionCards
              sessions={sessionsArray}
              deleteSession={(position) => deleteSessionFromArray(position)}
            />
            )
          }
          <IonRow className="ion-margin-top ion-justify-content-center">
            <IonButton
              disabled={!(sessionsArray.length === parseInt(sessionsQuantity, 10))}
              onClick={() => completeStep2()}
            >
              {t('SA_L24_1')}
            </IonButton>
          </IonRow>
        </>
      )}
      {(step3 && activeService) && (
        <>
          <Step2Header
            sessions={sessionsQuantity}
            service={activeService}
            returnText="changeServiceDates"
            returnStep={() => returnToStep2()}
          />
          {error}
          {completed}
          <Step3
            payToken={(token: string) => setPaymentToken(token)}
            history={(data: Sethistorysearch) => setSearchHistory(data)}
            clientResponse={formResponse}
            service={serviceSelected}
          />
        </>
      )}
      {error && (
        <ModalWrapper
          wrapperClass="ErrorModal"
          isOpen={errorModal}
          setIsOpen={() => setErrorModal(false)}
        >
          <ErrorModal />
        </ModalWrapper>
      )}
      {completed && (
        <ModalWrapper
          wrapperClass="successModal"
          isOpen={successModal}
          setIsOpen={() => setSuccessModal(false)}
        >
          <Success message={`${t('SA_L23')} ${t('SA_L23_1')}`} />
          <IonButton onClick={() => history.push('/user/dashboard')}>{t('menuDashboard')}</IonButton>
        </ModalWrapper>
      )}
    </IonGrid>
  );
};

export default RequestService;
