/* eslint-disable no-nested-ternary */
import {
  IonAvatar,
  IonButton, IonChip, IonContent, IonIcon,
} from '@ionic/react';
import { checkmarkCircleOutline, alertCircleOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../hooks/useFormatDate';
import { Balanceitem } from '../../interfaces';
import { AppointmentDetail } from '../../interfaces/clientInterfaces';
import { requestInvoice } from '../../services/client';
import { AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import './PaymentModal.scss';

interface ComponentProps {
  payment: Balanceitem & AppointmentDetail;
}

const BalanceModal: React.FC<ComponentProps> = ({ payment }) => {
  const { t } = useTranslation();
  const { formatIsoDate } = useFormatDate();
  const contextState = useContext(AppContextState);
  const { profile, services } = contextState;
  // eslint-disable-next-line camelcase
  const { user_profile } = profile;

  const [requestInvoiceState, setRequestInvoiceState] = useState<
    string | undefined
  >(undefined);

  const userType = user_profile.type === 'Client' ? 'expert' : 'client';
  const userUid = user_profile.type === 'Client'
    ? payment?.expert.uid
    : payment?.client.uid;

  const returnService = () => {
    const service = services
      && services.find((o) => o.id === payment.service_id);
    return service ? service.type : '';
  };

  const requestUserInvoice = (id: number | undefined) => {
    if (id) {
      const jwtToken = sessionStorage.getItem('jwt') || '';
      requestInvoice(jwtToken, id)
        .then(() => setRequestInvoiceState('ready'))
        .catch(() => setRequestInvoiceState('error'));
    } else {
      setRequestInvoiceState('error');
    }
  };

  return (
    <IonContent>
      <div className="ion-margin-bottom">
        <div className="header">
          <h4>{t('paymentModalHeader')}</h4>
        </div>
        <div className="userImage expert">
          <IonAvatar className="userProfileImage">
            <img
              src={userProfileImage(userType, userUid)}
              alt="user"
            />
          </IonAvatar>
          <p>
            <span>{payment.user_full_name}</span>
          </p>
        </div>
        <p className="message">
          <span className="label">{t('service')}</span>
          <span>{returnService()}</span>
        </p>
        <p className="message">
          <span className="label">{t('DC_L11')}</span>
          {payment.type === 'CancelledAppointment' ? (
            <span>{t('payCancelled')}</span>
          ) : payment.type === 'OpenAppointment' ? (
            <span>{t('payOpen')}</span>
          ) : payment.type === 'ScheduledAppointment' ? (
            <span>{t('payScheduled')}</span>
          ) : payment.type === 'InProgressAppointment' ? (
            <span>{t('payInprogress')}</span>
          ) : payment.type === 'ClosedAppointment' ? (
            <span>{t('payClosed')}</span>
          ) : (
            <span>{t('payCompleted')}</span>
          )}
        </p>
        <p className="message">
          <span className="label">{t('date')}</span>
          <span>
            {formatIsoDate(payment.start_date, 'DDD')}
          </span>
        </p>
        <p className="message">
          <span className="label">{t('startAt')}</span>
          <span>
            {formatIsoDate(payment.start_date, 'hh:mm a')}
          </span>
        </p>
        <p className="message">
          <span className="label">{t('endAt')}</span>
          <span>
            {formatIsoDate(payment.end_date, 'hh:mm a')}
          </span>
        </p>
        <div className="title text-center my-4">
          <p className="h3 m-0">{t('pay')}</p>
        </div>
        <p className="message">
          <span className="label">{t('payLabel')}</span>
          <span>{payment.paid ? t('CP_L7') : t('CP_L8')}</span>
        </p>
        <p className="message">
          <span className="label">{t('SA_L20')}</span>
          <span>
            {payment.service_price}
            {' '}
            USD
          </span>
        </p>
        <p className="message">
          <span className="label">{t('SA_L20_1')}</span>
          <span>
            -
            {payment.due_to_application}
            {' '}
            USD
          </span>
        </p>
        <p className="message">
          <span className="label">{t('SA_L20_2')}</span>
          <span>
            {payment.total_amount}
            {' '}
            USD
          </span>
        </p>
        {requestInvoiceState === 'ready' && (
        <IonChip color="success ion-margin-top">
          <IonIcon icon={checkmarkCircleOutline} />
          {t('requestSuccess')}
        </IonChip>
        )}
        {requestInvoiceState === 'error' && (
          <IonChip color="warning ion-margin-top">
            <IonIcon icon={alertCircleOutline} className="ion-margin-end" />
            {t('requestFailed')}
          </IonChip>
        )}
        {payment.invoice && payment.invoice.pdf_invoice_url ? (
          <IonButton
            href={payment.invoice.pdf_invoice_url}
            target="_blank"
            rel="external noopener noreferrer"
            shape="round"
            color="secondary"
            fill="solid"
            className="ion-margin-top"
          >
            {t('downloadInvoice')}
          </IonButton>
        ) : (
          <IonButton
            data-testid="test-request-invoice"
            shape="round"
            color="secondary"
            fill="solid"
            className="ion-margin-top"
            onClick={() => requestUserInvoice(payment.invoice?.id || undefined)}
          >
            {t('requestInvoice')}
          </IonButton>
        )}
      </div>
    </IonContent>
  );
};

export default BalanceModal;
