/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  IonSlides,
  IonSlide,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonCardSubtitle,
  IonIcon,
  IonText,
  IonContent,
} from '@ionic/react';
import { calendarNumberOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../../components/modalWrapper/ModalWrapper';
import AppoinmentModal from '../../../../components/userProfile/AppoinmentModal';
import UpdateAppointment from '../../../../components/userProfile/UpdateAppointment';
import useFormatDate from '../../../../hooks/useFormatDate';
import { AppointmentDetail } from '../../../../interfaces/clientInterfaces';
import { AppContextState } from '../../../../state/AppContext';
import { userProfileImage } from '../../../../utils';

type AppointmentsType = AppointmentDetail[] | undefined;
type ValidEventTypes = 'accept' | 'reject' | 'reschedule';

const RequestSlider: React.FC = () => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);

  const { formatIsoDate } = useFormatDate();
  const [appointmentModal, setAppointmentModal] = useState<AppointmentDetail | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<AppointmentsType>(undefined);
  const [appointmentUpdate, setAppointmentUpdate] = useState<AppointmentDetail>();
  const [updateEvent, setUpdateEvent] = useState<ValidEventTypes>();
  const { allAppointments, services } = contextState;

  const [slideOpts, setSlideOpts] = useState({
    initialSlide: 0,
    speed: 400,
    slidesPerView: `${Math.floor(window.innerWidth / 298)}.3`,
  });

  function changeSlideOpts(key: string, value: string) {
    setSlideOpts({
      ...slideOpts,
      [key]: parseFloat(value),
    });
  }

  window.onresize = () => {
    changeSlideOpts(
      'slidesPerView',
      `${Math.floor(window.innerWidth / 298)}.3`,
    );
  };

  useEffect(() => {
    sortAppointments();
  }, [allAppointments]);

  const changeModalToReschedule = (type: ValidEventTypes) => {
    setUpdateEvent(undefined);
    setTimeout(() => {
      setUpdateEvent(type);
      setAppointmentUpdate(appointmentUpdate);
    }, 500);
  };

  const closeUpdateModal = () => {
    setAppointmentUpdate(undefined);
    setUpdateEvent(undefined);
  };

  const sortAppointments = () => {
    setOpen([]);
    const openAppointments: AppointmentDetail[] = [];
    allAppointments?.sort(
      (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
    );
    allAppointments?.forEach((element) => {
      if (element.type === 'OpenAppointment') {
        openAppointments.push(element);
      }
    });
    setTimeout(() => {
      setOpen(openAppointments);
    }, 700);
  };

  if (!allAppointments || allAppointments.length <= 0) {
    return <p>{t('appNoPending')}</p>;
  }

  if (open && open.length > 0) {
    return (
      <div className="ion-margin-top">
        <div className="section-title text-center">
          <h2>{t('edRequests')}</h2>
          <span className="title-border" />
        </div>
        <IonSlides id="request-slider" options={slideOpts}>
          {open
            && open.map((appointment) => {
              let service;
              const end = DateTime.fromISO(appointment.end_date);
              const endControl = end.diff(DateTime.now());
              if (services) {
                service = services.find((o) => o.id === appointment.service_id);
              }
              return (
                <IonSlide key={appointment.id}>
                  <IonCard className="request-card">
                    <div className="image-container">
                      <img
                        data-testid="test-request-image"
                        onClick={() => setAppointmentModal(appointment)}
                        className="user-image"
                        src={userProfileImage('client', appointment.client.uid)}
                        alt="Client request service"
                      />
                      <IonText className="image-card-name">{appointment.client.first_name}</IonText>
                    </div>
                    <IonCardHeader>
                      <IonCardSubtitle data-testid="test-request-subtitle" onClick={() => setAppointmentModal(appointment)}>
                        {!appointment.is_reschedule && (
                        <IonText>
                          {formatIsoDate(appointment.start_date)}
                        </IonText>
                        )}
                        {appointment.is_reschedule && (
                        <div className="rescheduleDateContainer">
                          <IonText
                            className={`${appointment.is_reschedule ? ' cancelled ' : ''}`}
                          >
                            {appointment
                              ? formatIsoDate(appointment.start_date) : ''}
                          </IonText>
                          <IonText className="rescheduleNew">
                            {appointment
                              ? formatIsoDate(appointment.reschedule_date) : ''}
                          </IonText>
                        </div>
                        )}
                      </IonCardSubtitle>
                      <IonCardTitle className="request-card-title">
                        <IonText onClick={() => setAppointmentModal(appointment)}>
                          {service?.type.includes('xpress')
                            ? t('express_service')
                            : t(`${service?.type.toLowerCase()}_service`)}
                        </IonText>
                        <IonText onClick={() => setAppointmentModal(appointment)}>
                          {appointment.package_of && (
                          <span>
                            {appointment.package_of}
                            {' '}
                            /
                              {appointment.package_total}
                          </span>
                          )}
                        </IonText>
                        {endControl.milliseconds > 0 && (
                          <IonButton
                            color="secondary"
                            fill="outline"
                            shape="round"
                            data-testid="test-request-reschedule"
                            className="ion-no-padding reschedule-button"
                            onClick={() => {
                              setAppointmentUpdate(appointment);
                              setUpdateEvent('reschedule');
                            }}
                          >
                            <IonIcon
                              className="reschedule-icon"
                              slot="icon-only"
                              icon={calendarNumberOutline}
                            />
                          </IonButton>
                        )}
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonText
                        onClick={() => setAppointmentModal(appointment)}
                        className="line_clamp ion-margin-bottom"
                      >
                        {appointment.client.about.aboutBody
                          ? appointment.client.about.aboutBody
                          : ''}
                      </IonText>
                      {endControl.milliseconds > 0 ? (
                        <div className="justify-content-center">
                          <IonButton
                            data-testid="test-request-accept"
                            shape="round"
                            size="small"
                            onClick={() => {
                              setAppointmentUpdate(appointment);
                              setUpdateEvent('accept');
                            }}
                          >
                            {t('accept')}
                          </IonButton>
                          <IonButton
                            data-testid="test-request-reject"
                            shape="round"
                            fill="outline"
                            size="small"
                            onClick={() => {
                              setAppointmentUpdate(appointment);
                              setUpdateEvent('reject');
                            }}
                          >
                            {t('reject')}
                          </IonButton>
                        </div>
                      ) : (
                        <IonButton color="medium" shape="round" size="small" disabled>
                          {t('notTaken')}
                        </IonButton>
                      )}
                    </IonCardContent>
                  </IonCard>
                </IonSlide>
              );
            })}
        </IonSlides>
        {
          !!appointmentModal
          && (
            <ModalWrapper
              wrapperClass="bigModal"
              closeBtnProps={{ className: 'closeBtn', color: 'primary' }}
              isOpen={!!appointmentModal}
              setIsOpen={() => setAppointmentModal(undefined)}
            >
              <AppoinmentModal appointmentDetail={appointmentModal} />
            </ModalWrapper>
          )
        }
        {(!!appointmentUpdate && !!updateEvent) && (
          <ModalWrapper
            wrapperClass={
              `update-appointment-modal 
              ${(updateEvent === 'reschedule' && appointmentUpdate.is_reschedule) && 'bigModal'}`
            }
            isOpen={!!appointmentUpdate}
            setIsOpen={() => closeUpdateModal()}
          >
            <IonContent>
              <UpdateAppointment
                appoinment={appointmentUpdate}
                eventType={updateEvent}
                setEventType={(type: ValidEventTypes) => changeModalToReschedule(type)}
                closeModal={closeUpdateModal}
              />
            </IonContent>
          </ModalWrapper>
        )}
      </div>
    );
  }

  return null;
};

export default RequestSlider;
